import { Column } from "react-table";
import { DashboardData, DashboardKeys } from "../../services/dashboard";
import { defaultColumnIndex } from "../../utils/constants";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export const getGroups = (dashboardTableData: DashboardData) => {
	let groupIds: string[] = [];
	let groupIdIndex: { [key: string]: number } = {};
	dashboardTableData.keys.map((data: DashboardKeys) => {
		if (data?.groupId && !groupIds.includes(data.groupId.charAt(0).toUpperCase() + data.groupId.slice(1))) {
			groupIds.push(data.groupId.charAt(0).toUpperCase() + data.groupId.slice(1));
			groupIdIndex[data.groupId] = typeof data.groupIdIndex === "number" ? data.groupIdIndex : defaultColumnIndex;
		} else if (data.groupId && groupIdIndex[data.groupId] === defaultColumnIndex) {
			groupIdIndex[data.groupId] = typeof data.groupIdIndex === "number" ? data.groupIdIndex : defaultColumnIndex;
		}
	});

	return { groupIds, groupIdIndex };
};

export const getHiddenColumns = (columns: Column<object>[]) => {
	let columnsToHide: any[] = [];

	const getHiddenColumnfunc = (columns: Column<object>[]) => {
		columns.map((column: any) => {
			if (column.isVisible === false) {
				columnsToHide.push(column.accessor);
			}
			if (column?.columns?.length) {
				getHiddenColumnfunc(column.columns);
			}
		});
	};

	getHiddenColumnfunc(columns);

	return columnsToHide;
};

export const orderColumnsByPinning = (columns: Column<object>[]) => {
	const pinnedColumns: { id: any; pin: any }[] = [];
	const columnsData = columns.slice();
	columns.forEach((column) => {
		if ((column as any).pin === "left" || (column as any).pin === "right") {
			pinnedColumns.push({ id: (column as any)?.id, pin: (column as any)?.pin });
		} else return;
	});

	pinnedColumns?.forEach((col) => {
		if (col?.pin === "right") {
			const index = columnsData.findIndex((data: any) => data.id === col.id);
			columnsData.push(columnsData.splice(index, 1)[0]);
		} else if (col?.pin === "left") {
			const index = columnsData.findIndex((data) => data.id === col.id);
			columnsData.splice(1, 0, columnsData.splice(index, 1)[0]);
		}
	});

	return columnsData.map((data) => data.id as string);
};

export const createExportableData = (dashboardTableData: DashboardData, tableData: object[]) => {
	let exportRows = [] as any;
	if (dashboardTableData) {
		//create exportable data
		//sort by group id
		let sortedTableDataByGroup = JSON.parse(JSON.stringify(dashboardTableData));
		if (sortedTableDataByGroup) {
			sortedTableDataByGroup = sortedTableDataByGroup.keys.sort((a: any, b: any) => {
				if (!a.groupId) {
					return 1;
				} else if (!b.groupId) {
					return -1;
				} else {
					if (a.groupId < b.groupId) {
						return -1;
					}
					if (a.groupId > b.groupId) {
						return 1;
					}
					return 0;
				}
			});
		}
		//create export data
		exportRows = tableData?.map((tableRow: any) => {
			let exportRow = {
				Markets: tableRow.markets || " ",
			} as any;
			sortedTableDataByGroup?.forEach((data: any) => {
				exportRow[`${data["groupId"] ? data["groupId"] : ""}${data["groupId"] ? "." : ""}${data["flagga-label"]}`] =
					tableRow[data.key] !== "undefined" && tableRow[data.key] !== undefined ? tableRow[data.key] : "";
			});
			return exportRow;
		});
	}
	return exportRows;
};

const getAfterDot = (str: string) => {
	const parts = str.split(".");
	return parts.length > 1 ? parts[1] : str;
};

export const exportGlobalDashboardTable = async (data: any, fileName: string, columnGroupingData: any[]) => {
	// Create a new workbook and add a worksheet
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet("GlobalDashboard");

	let columnStart = 1;
	const row = worksheet.getRow(1);
	// merge a range of cells
	columnGroupingData &&
		columnGroupingData.forEach((groupingColumn: any) => {
			if (groupingColumn.columns?.length > 0) {
				row.getCell(columnStart).value = groupingColumn.Header;
				row.getCell(columnStart).fill = {
					type: "pattern",
					pattern: "solid",
					fgColor: { argb: "4D81BE" },
				};
				row.getCell(columnStart).font = {
					color: { argb: "FFFFFF" },
					bold: true,
				};
				// add white border to grouping column
				row.eachCell((cell) => {
					cell.border = {
						top: { style: "thin", color: { argb: "FFFFFFFF" } }, // White border
						left: { style: "thin", color: { argb: "FFFFFFFF" } },
						bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
						right: { style: "thin", color: { argb: "FFFFFFFF" } },
					};
				});
				row.getCell(columnStart).alignment = { horizontal: "center", vertical: "middle" };
				worksheet.mergeCells(1, columnStart, 1, columnStart + groupingColumn.columns.length - 1);
				columnStart = columnStart + groupingColumn.columns.length;
			}
		});

	worksheet.addTable({
		name: "GlobalDashboard",
		ref: columnGroupingData && columnGroupingData.length > 0 && columnGroupingData[0]?.columns ? "A2" : "A1",
		headerRow: true,
		columns: Object.keys(data[0]).map((columnKey) => ({
			name: getAfterDot(columnKey),
		})),
		rows: data.map((row: any) => Object.values(row)),
	});

	// Save the workbook as an Excel file
	workbook.xlsx.writeBuffer().then(function (buffer) {
		var blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
		saveAs(blob, `${fileName}.xlsx`);
	});
};
