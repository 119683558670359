import * as React from "react";
import { IconProps } from "./types";

export const Checkmark: React.FC<IconProps> = ({ height, width, colour }) => {
	return (
		<svg
			width={`${width}` || "24"}
			height={`${height}` || "24"}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.5591 7.5 18 8.9608 10.5636 16.5 6 11.8732l1.4409-1.4608 3.1227 3.166L16.5591 7.5z"
				fill={colour || "#111"}
			/>
		</svg>
	);
};
